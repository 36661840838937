import mixins from '../../romaCommon.js'
import api from '@/Api';
import { AccountStatementTypes } from '../../config/constants.js'

const getDefaultState = () => {
  return {
    error: null,
    accountStatement: null,

    reportFrequency: 'DAILY',
    selectedAccountName: null,
    selectedStatementType: 'ms', 
    activityOnly: false,

    selectedDateRange: null, 

    reportType: null,
  }
}

const state = getDefaultState();

const mutations = {
  setError(state, error) {
    state.error = error;
  },
  setSelectedDateRange(state, range) {
    state.selectedDateRange = range
  },
  setAccountStatement(state, accountStatement) {
    state.accountStatement = accountStatement
  },
  setReportFrequency(state, reportFrequency) {
    state.reportFrequency = reportFrequency
  },
  setSelectedAccountName(state, name) {
    state.selectedAccountName = name
  },
  setSelectedStatementType(state, value) {
    state.selectedStatementType = value;
  },
  setActivityOnly(state, value) {
    state.activityOnly = value
  },
  setReportType(state, value) {
    state.reportType = value
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  accountStatement: state => {
    return state.accountStatement
  },
  accountStatementRows: state => {
    return state.accountStatement ? state.accountStatement.rows : []
  },
  statementsListEnabled: (state, getters) => {
    return getters.availableStatementsList.length > 1
  },
  availableStatementsList: (state, getters, rootState, rootGetters) => {
    if (rootGetters['account/hasMultiplier']) {
      return [ 
        { text: AccountStatementTypes.MAIN, value: 'main' },
        { text: AccountStatementTypes.X, value: 'x' }, 
        { text: AccountStatementTypes.TOTAL, value: 'main,x' }];
    } else {
      return [{ text: AccountStatementTypes.MAIN, value: 'main' }];
    }
  },
  reportType: state => {
    return state.reportType
  },
  reportFromDate: state => {
    return state.accountStatement?.fromDate
  },
  reportToDate: state => {
    return state.accountStatement?.toDate
  }
}

const actions = {

  async loadAccountStatement({ commit,state}) {
    const params = { 
      period: state.reportFrequency,
      fromDate: mixins.formatJsDate_localTZ(state.selectedDateRange[0]),
      toDate: mixins.formatJsDate_localTZ(state.selectedDateRange[1]),
      accountTypes: state.selectedStatementType,
      activityOnly: state.activityOnly,
    }
    commit('setError', null)
      
      const response = (state.reportType === 'ORG') 
          ? api.getOrgAccountStatement(params)
          : api.getAccountStatement(state.selectedAccountName, params)
          
    return response.then(response => {
      console.log("customer statement data loaded: ", response.data)
      commit('setAccountStatement', response.data)
      return response
    }).catch (error => {
      console.log("failed to load Cash Balance Summary:", error)
      commit('setError', { 
        message: "Failed to load Cash Balance Summary: ",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    })
  },
  setSelectedStatementAction({ commit, dispatch }, value) {
    commit('setSelectedStatementType', value)
    return dispatch('loadAccountStatement')
  },
  setSelectedDateRange({ commit, dispatch }, dateRange) {
    commit('setSelectedDateRange', dateRange)
    return dispatch('loadAccountStatement')
  },
  setReportFrequency({ commit, dispatch }, frequency) {
    commit('setReportFrequency', frequency)
    return dispatch('loadAccountStatement')
  },
  setActivityOnly( { commit, dispatch }, value) {
    commit('setActivityOnly', value)
    return dispatch('loadAccountStatement')
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}