<template>
  <router-view/>
</template>
<script>
import { DetailRow, Aggregate, Group, Sort, Freeze, Scroll, Search, Toolbar, Edit, CommandColumn, ForeignKey, Page, /*PdfExport,ExcelExport */} from '@syncfusion/ej2-vue-grids'
import { Sort as Sort1, Freeze as Freeze1, Filter, Toolbar as Toolbar1 } from '@syncfusion/ej2-vue-treegrid'
import { SparklineTooltip } from '@syncfusion/ej2-vue-charts'
import { ToastComponent } from '@syncfusion/ej2-vue-notifications'

export default {
  name: 'TheHeaderDropdownSettings',
  provide: { 
    // the syncfusion grid components need to be provided at an app level because of an issue rendering through the router.
    // see https://www.syncfusion.com/forums/166897/how-to-provide-modules-to-data-grid-in-vue-3-composition-api-using-provide-inject-pattern
    grid: [DetailRow,Aggregate,Group,Sort,Freeze,Scroll,Search, Toolbar,Edit,CommandColumn,ForeignKey,Page/*PdfExport,ExcelExport*/],
    treegrid: [Sort1,Freeze1,Filter,Toolbar1],
    sparkline: [SparklineTooltip],
    Toast: [ToastComponent],
  },
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'styles/style';
  @import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
  @import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
  @import "../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-grids/styles/bootstrap5.css";
  @import '../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css';
  @import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-treegrid/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-buttons/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-inputs/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-dropdowns/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-lists/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-popups/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-notifications/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-navigations/styles/bootstrap5.css";
  @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
  @import "../node_modules/@syncfusion/ej2-vue-spreadsheet/styles/bootstrap5.css";
  @import "../node_modules/@syncfusion/ej2-vue-layouts/styles/bootstrap5.css";
  
  @import url("https://fonts.googleapis.com/css?family=Roboto:400,500");

  .e-control, .e-css { 
    font-family: "Roboto", san-serif !important;  
  }
  
  .badge.badge-outline {
    border: 1px solid #999;
    background-color: transparent;
  }
  .badge.badge-outline.badge-success {
    border-color: rgba(var(--cui-success-rgb), var(--cui-bg-opacity)) !important;
    color: rgba(var(--cui-success-rgb), var(--cui-bg-opacity)) !important;
  }
  .badge.badge-outline.badge-warning {
    border-color: rgba(var(--cui-warning-rgb), var(--cui-bg-opacity)) !important;
    color: rgba(var(--cui-warning-rgb), var(--cui-bg-opacity)) !important;
  }
  .badge.badge-outline.badge-primary {
    border-color: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity)) !important;
    color: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity)) !important;
  }
  .badge.badge-outline.badge-danger {
    border-color: rgba(var(--cui-danger-rgb), var(--cui-bg-opacity)) !important;
    color: rgba(var(--cui-danger-rgb), var(--cui-bg-opacity)) !important;
  }
  .badge.badge-outline.badge-secondary {
    border-color: rgba(var(--cui-secondary-rgb), var(--cui-bg-opacity)) !important;
    color: rgba(var(--cui-secondary-rgb), var(--cui-bg-opacity)) !important;
  }
  .badge.badge-outline.badge-info {
    border-color: rgba(var(--cui-info-rgb), var(--cui-bg-opacity)) !important;
    color: rgba(var(--cui-info-rgb), var(--cui-bg-opacity)) !important;
  }

  .e-badge.yellow {
    background: #fce762;
    color: #000;
  }
  .e-badge-small {
    font-size: 9.6px;
    height: 15px;
  }

  .e-multiselect .e-multi-select-wrapper .e-chips .e-chipcontent {
    font-size: 9px;
  }
  .e-multi-select-wrapper {
    padding: 0 5px 0 0;
  }

  .e-treecell  div {
    display: inline;
  }
  .e-grid-min-height .e-rowcell {
    line-height: 23px !important;
  }
  .e-grid .e-altrow {
    background-color: #fafafa;
  }
  /*
  .e-grid .e-headercell {
    padding: 0 4px 0;
    font-size: 10px;
  }
  */
  .e-grid .e-headercelldiv {
    font-size: 12px;
  }
  .e-grid .e-rowcell {
    padding: 0 2px 0;
    font-size: 11px;
  }
  .e-grid .e-gridheader .e-sortfilter .e-headercelldiv {
    padding: 0 1.0em 0 0.6em;
  }
  .e-grid .e-summaryrow .e-summarycell {
    font-weight: 700;
    font-size: 11px;
    padding-right: 0px;
    padding-bottom: 4px;
    padding-top: 8px;
    background-color: #e3e0e0;
  }
  
  .e-grid .e-summaryrow .e-summarycell:last-child {
    padding-right: 8px;
  }

  .e-grid .e-detailcell {
    border-color: #ffffff !important;
  }
  // override syncfusion badge colors to match theme
  .e-badge-warning {
    background-color: rgb(var(--cui-warning-rgb)) !important;
  }

  :not(.e-summarycell) > .roma-money-positive:last-child {
    padding-right: 11px !important;
  }
  .roma-money-positive {
    padding-right: 5px !important;
  }
  // aggregate totals column alignments
  //.e-summarycell .roma-money-positive {
  //  padding-right: 2px !important;
  //}

  :not(.e-summarycell) > .roma-money-negative:last-child {
    padding-right: 7px !important;
    color: var(--cui-danger) !important;

  }
  .roma-money-negative {
    color: var(--cui-danger) !important;
  }

  .roma-money-negative-blk:last-child {
    padding-right: 7px !important;

  }
  .roma-percent-negative {
    color: var(--cui-danger) !important;
  }

  .e-dialog, .e-dialog .e-dlg-header-content {
    background-color: var(--cui-gray-100);
    padding: 8px;
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    color: var(--cui-gray-500)
  }
  .text-danger {
    color: var(--cui-red) !important;
  }
  .header-icon {
    position: relative;
    top: 2px;
    margin-right: 4px;
  }
  .btn-icon {
    font-size: 16px; 
    font-weight: 700; 
    position: relative; 
    top: 2px;
  }
  .rate-warning {
    background-color:  #fff3cd;
    border-color: #ffecb5;
    color: #664d03;
  }
  .rate-warning-icon:before {
    content: '\e88b'
  }
  .rate-label {
    font-size: 10.5px;
  }
  .trans-rate-label {
    font-size: 10.5px;
    color: rgb(127, 138, 146)
  }
  .rate-label-override {
    color: #CD5C5C;
    font-weight: 500;
  }
  .trans-label {
    font-size: 12px;
    font-weight: 500;
    color: rgb(108,117,125)
  }
  .trans-detail-card {
    background-color: #f5f5f5;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 6px;
  }
  .label-checkbox.e-checkbox-wrapper .e-label {
    font-size: 12px;
    font-weight: 500;
    color: rgb(108,117,125)
  }
  .currency-label {
    font-size: 10.5px;
  }
  .left-tx-control-group {
    width: 44px;
  }
  .text-tiny {
    font-size: 0.775em;
  }
</style>
